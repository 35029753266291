import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Helmet from 'react-helmet'
import { withRouter, Redirect } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import loadable from '@loadable/component'
import { Sidebar, Segment, Menu } from 'semantic-ui-react'
import ScrollToTop from 'ion-scrolltotop'
import { Mobile } from 'ion-media'
import { url2section } from 'ion-sections'
import { enableSingleRequest, enableInterstitial, enableLazyLoad, enableMappings, disableMappings } from 'ion-ads'

import Header from './Shell/Header'
import Footer from './Shell/Footer'
import MobileMenu from './Shell/MobileMenu'

import FaviconApple from '../static/favicon/apple-touch-icon.png'
import Favicon32 from '../static/favicon/favicon-32x32.png'
import Favicon16 from '../static/favicon/favicon-16x16.png'
import FaviconSafari from '../static/favicon/safari-pinned-tab.svg'
import FaviconICO from '../static/favicon/favicon.ico'

import logo from '../static/general/isolezwe.svg'

import { hideMenu, toggleMenu, toggleSearch, pageView, onSlotRenderEndedLeft, onSlotRenderEndedRight } from '../../store/app'
import { fetchArticles, fetchMoreArticles } from '../../store/articles'

import 'semantic-ui-css/components/sidebar.min.css'
import 'semantic-ui-css/components/segment.min.css'
import 'semantic-ui-css/components/menu.min.css'
// import 'semantic-ui-css/semantic.min.css'
import '../styles/app.scss'

export const HomePage = loadable(() => import('../pages/LandingPages/HomePage'))
export const NotFound = loadable(() => import('../pages/NotFound'))
export const DefaultSection = loadable(() => import('./DefaultSection'))
export const Article = loadable(() => import('../pages/Article'))
export const StaticPage = loadable(() => import('../pages/StaticPage'))
export const SearchPage = loadable(() => import('../pages/SearchPage'))
export const Feedback = loadable(() => import('../pages/Feedback'))
export const WeatherPage = loadable(() => import('../pages/WeatherPage'))
export const AuthorsSectionPage = loadable(() => import('../pages/AuthorsSectionPage'))

// GPT.enableSingleRequest()

export class App extends Component {
  constructor (props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.hideMenu = this.hideMenu.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.state = {
      wingbannerLeftActive: false,
      wingbannerRightActive: false,
      hamburgerActive: false,
      useSmall: false
    }
    // this.props.pageView(this.props.location.pathname)
  }

  componentDidUpdate (prevProps) {
    // Re-fetch articles when the contentKey changes
    if (prevProps.location.pathname !== this.props.location.pathname) {
      setTimeout(() => {
        this.props.pageView(this.props.location.pathname)
      }, 0)
    }
  }

  toggleSearch () {
    this.props.toggleSearch()
  }

  toggleMenu () {
    this.props.toggleMenu()
  }

  hideMenu () {
    if (this.props.showMenu) {
      this.props.hideMenu()
    }
  }

  render () {
    return (
      <>
        <div className='app'>
          <Helmet
            defaultTitle={process.env.RAZZLE_SITE_TITLE}
          >
            <html lang='zu' />
            <link rel='icon' type='image/x-icon' href={FaviconICO} />
            <link rel='apple-touch-icon' sizes='180x180' href={FaviconApple} />
            <link rel='icon' type='image/png' sizes='32x32' href={Favicon32} />
            <link rel='icon' type='image/png' sizes='16x16' href={Favicon16} />
            <link rel='mask-icon' href={FaviconSafari} color='#5bbad5' />
            <meta name='msapplication-TileColor' content='#ffffff' />
            <meta name='theme-color' content='#ffffff' />
            <meta name='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
            <meta name='msvalidate.01' content='5A0910AA1A12E32A5352933CF5FD96E9' />
            <meta property='fb:app_id' content='1915095138801045' />
            <meta property='og:type' content='article' />
            <meta charset='utf-8' />
            <meta property='og:title' content={process.env.RAZZLE_SITE_TITLE} />
            <meta property='og:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
            <meta property='og:image' content={logo} />
            <meta itemprop='inLanguage' content='zu' />
            <meta itemprop='image' content={logo} />
            <meta name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover' />
            <meta name='mobile-web-app-capable' content='yes' />
            <meta name='theme-color' content='#ffffff' />
            <meta name='google-site-verification' content='pzAffsof8VshOp53XmZa9qZwwSMWIzKq_U8TnBj93U8' />
            <meta itemprop='image' content={logo} />
            <meta itemprop='thumbnailUrl' content={logo} />
            <meta name='twitter:site' content={process.env.RAZZLE_SITE_TWITTER_USER} />
            <meta name='twitter:creator' content={process.env.RAZZLE_SITE_TWITTER_USER} />
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:image:src' content={logo} />
            <link rel='canonical' itemprop='url' href={process.env.RAZZLE_SITE_URL + (this.props.location.pathname === '/' ? '' : this.props.location.pathname)} />
            <body className={(this.props.showMenu ? 'menu-open' : 'menu-closed') + ((this.props.wingbannerLeftActive || this.props.wingbannerRightActive) ? ' dfp-take-over-ad' : '')} />
          </Helmet>
          <Sidebar.Pushable as={Segment}>
            <Sidebar as={Menu} animation='push' width='wide' visible={this.props.showMenu} icon='labeled' vertical>
              <Mobile>
                <MobileMenu toggleMenu={this.toggleMenu} />
              </Mobile>
            </Sidebar>
            <Sidebar.Pusher dimmed={this.props.showMenu} onClick={() => this.hideMenu}>
              <Header toggleMenu={this.toggleMenu} toggleSearch={this.toggleSearch} {...this.props} />
              <main id='main-content' role='main' className='content'>
                <ScrollToTop>
                  <Switch>
                    <Route exact path='/feedback/thankyou' render={() => <Feedback thanksMessage='Thank you for your feedback. We will get back to you shortly.' />} />
                    <Route exact path='/ifomu-lombiko' component={Feedback} />
                    <Route exact path='/' render={p => <HomePage {...this.props} />} />
                    <Route exact path='/shell' render={() => <div>Loading...</div>} />
                    <Route
                      exact path='/search' render={(props) =>
                        <SearchPage
                          {...this.props}
                          twitterName={process.env.RAZZLE_SITE_TWITTER_USER}
                          titleTemplate={'%s | ' + process.env.RAZZLE_SITE_TITLE}
                        />}
                    />
                    <Route
                      path='/authors' exact render={props =>
                        <AuthorsSectionPage
                          section='authors'
                          title='Authors Page'
                          description='Authors Page'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(inqubomgomo-ezimele)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Inqubomgomo ezimele'
                          description='The privacy policy for Isolezwe, the South African News website'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(imigomo-nemibandela)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Imigomo nemibandela'
                          description='Terms and conditions for Isolezwe, the South African News website.'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(xhumana-nesolezwe)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Xhumana neSolezwe | Isolezwe'
                          description='Sishayele noma sithumele i-email ngofisa ukukudlulisa kwithimba lezintatheli noma kuMhleli wePhephandaba.'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(umlando-wesolezwe)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Umlando weSolezwe | Isolezwe'
                          description='Eminye imininingwano ngesolezwe nethimba labahleli.'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(rss-feeds)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title={'RSS Feeds | ' + process.env.RAZZLE_SITE_TITLE}
                          description={'RSS Feeds | ' + process.env.RAZZLE_SITE_DESCRIPTION}
                          {...this.props}
                        />}
                    />
                    {/* Defaults and direct article access */}
                    <Route path='/preview' render={props => <Article {...this.props} {...props} url='preview' contentKey={999999} />} />
                    <Route path='/:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})' render={props => <Article {...this.props} {...props} contentKey={props.match.params.contentKey} />} />
                    <Route path='/:contentKey([0-9]{5,})' render={props => <Article {...this.props} {...props} contentKey={props.match.params.contentKey} />} />
                    <Route path='/:section([a-zA-Z0-9-]+)/' strict exact render={props => <Redirect to={'/' + props.match.params.section} />} />
                    <Route path='/:section([a-zA-Z0-9-]+)' render={props => <DefaultSection {...this.props} {...props} twitterName={process.env.RAZZLE_SITE_TWITTER_USER} />} />
                    {/* <Route exact path='/404' component={NotFound} {...this.props} /> */}
                    <Route path='*' component={NotFound} {...this.props} />
                  </Switch>
                </ScrollToTop>
              </main>
              <Footer showSubscribe={(this.props.location.pathname !== '/subscribe/newsletters')} section={url2section(this.props.location.pathname)} />
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </div>
      </>
    )
  }
}

/**
 * enable Single Request Mode for Ads
 *
 * This setting requires a call to `performSingleRequest` to initiate the fetch. This is currently achieved using sagas,
 * leveraging `onSlotRegistered` to trigger an action, and debouncing the events to catch the last one in the series to
 * trigger the `performSingleRequest` call.
 */
enableSingleRequest()
enableInterstitial()
if (process.env.RAZZLE_ENABLE_GAM && process.env.RAZZLE_ENABLE_LAZYLOAD) {
  enableLazyLoad()
}
if (process.env.RAZZLE_ENABLE_MAPPINGS) {
  enableMappings()
} else {
  disableMappings()
}

const mapStateToProps = (state) => (state.app)
const mapDispatchToProps = (dispatch) => bindActionCreators({ hideMenu, toggleMenu, toggleSearch, pageView, fetchArticles, fetchMoreArticles, onSlotRenderEndedLeft, onSlotRenderEndedRight }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
